import {
  faCheckCircle,
  faFileContract,
  faFileExport,
  faGlobe,
  faSmile,
  faStore,
  faUserCheck,
  faUserFriends,
  faUsersCog,
  faLaptop,
  faHistory,
  faRupeeSign,
  faShieldAlt,
  faServer,
  faTasks,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import TPImg from "../../content/assets/illustrations/taxprof.svg"
import TrustImg from "../../content/assets/illustrations/trust.svg"
// import "../utils/global.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "react-tabs/style/react-tabs.css"
import "../utils/css/screen.css"
import { faYoutube } from "@fortawesome/free-brands-svg-icons"
import Slider from "react-slick"
import testimonials from "../data/testimonials.json"
import { TestimonialSlide } from "../components/testimonial"

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const Index = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    margin: 10,
    arrows: false,
  }
  return (
    <Layout title={siteTitle} currentLink="/">
      <SEO
        title="Optotax - Fast and Simple GST Filing and Reports"
        description="India's No 1 GST Platform"
        keywords={[
          `GST`,
          `GST Reports`,
          `GST Filing`,
          `Free GST Software`,
          "Indian GST Software",
          "Chartered Accountant",
          "GST Registered Tax Practitioner",
          "Tax Practitioner",
          "Tax Professional",
          "GST for Tax Professional",
          "GST for Tax Practitioner",
          "GST for Accountants",
        ]}
      />
      <div className="w-100 announcement">
        <h3>
          <Link to={`/acquired`} activeClassName="active-menu-link">
            Optotax is now a part of Open Financial Technologies.
          </Link>
        </h3>
      </div>
      <div className="banner banner-bg main-hero">
        <Img fluid={data.hero.childImageSharp.fluid} className="hero-img" />
        <div className="banner-body hero-body">
          <div className="banner-content">
            <h1 className="caption">
              Free and simple GST Return Filing and Reporting
            </h1>

            <h4 className="caption">
              Optotax helps Chartered Accountants, Tax Advocates, and Tax
              Practitioners manage GST compliance for their clients effectively
              with seamless collaboration.
            </h4>
            <div className="cta-btns">
              <div className="caption">
                <a
                  href="https://opto.tax/website-demo-request"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button large"
                >
                  Get a FREE Demo!
                </a>
              </div>
              <div className="caption">
                <a
                  href="https://gst.optotax.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button large"
                >
                  Login/Signup
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-video">
          <Img fluid={data.hero.childImageSharp.fluid} className="hero-img" />
        </div>
      </div>

      <div className="metrics">
        <div className="text-center">
          <h3 className="m2">
            <FontAwesomeIcon icon={faSmile} size="lg" />
          </h3>
          <h3> 50000+ Happy Tax Professionals</h3>
        </div>
        <div className="text-center">
          <h3 className="m2">
            <FontAwesomeIcon icon={faGlobe} size="lg" />
          </h3>
          <h3>10 Lakh Businesses</h3>
        </div>
        <div className="text-center">
          <h3 className="m2">
            <FontAwesomeIcon icon={faFileExport} size="lg" />
          </h3>
          <h3>5 Million GST Returns filed</h3>
        </div>
      </div>

      <div className="sections text-center">
        <div className="section sec1">
          <div className="section-txt">
            <h3>India's most trusted GST platform.</h3>
            <h5 className="section-text">
              <p>
                Use OPTOTAX, which is trusted by thousands of CAs, Tax Advocates
                and Tax Practitioners from all parts of India.
              </p>
              <p>
                {" "}
                OPTOTAX helps Tax Professionals from all across the country to
                file GST returns and download full year reports in Excel.
                Optotax is a powerful tool that make the whole process easier
                and much more efficient for Tax Professionals.
              </p>
            </h5>
          </div>
        </div>
        <div className="section-image img1">
          <TrustImg />
        </div>
        <div className="section sec2">
          <div className="section-txt">
            <h3>Exclusively For Tax Professionals.</h3>
            <h5 className="section-text">
              <p>
                The best Tax Professionals are those who can effectively
                collaborate with their clients. Effective collaboration allows
                you to be more reliable, flexible, and scalable. Above all,
                working together helps turn transactions into lasting
                relationships.
              </p>
              <p>
                OPTOTAX helps Tax Professionals manage their clients
                effectively. Adding and managing clients, Automatic Tax
                Computations for GST, Seamless GST Returns preparation, GSTN OTP
                Authentication and GST Return Filing in a one-stop end-to-end
                solution.
              </p>
            </h5>
          </div>
        </div>
        <div className="section-image img2">
          <TPImg />
        </div>
      </div>

      <div className=" row justify-space-between align-center features">
        <div className="col-4 text-center">
          <h3>
            <FontAwesomeIcon size="lg" icon={faUserCheck} />
          </h3>
          <h3 className="m2">Only for Tax Professionals</h3>
          <h5 className="section-text">
            <p>Exclusive GST platform available only for TAX Professionals.</p>
          </h5>
        </div>
        <div className="col-4 text-center">
          <h3>
            <FontAwesomeIcon size="lg" icon={faUserFriends} />
          </h3>
          <h3 className="m2">Managing clients</h3>
          <h5 className="section-text">
            <p>
              Single screen to manage all your clients from a single login ID.
            </p>
          </h5>
        </div>
        <div className="col-4 text-center">
          <h3>
            <FontAwesomeIcon size="lg" icon={faFileContract} />
          </h3>
          <h3 className="m2">Full Year reports</h3>
          <h5 className="section-text">
            <p>
              All reports in excel format which help analyze year round filings
              and data.
            </p>
          </h5>
        </div>
        <div className="col-4 text-center">
          <h3>
            <FontAwesomeIcon size="lg" icon={faStore} />
          </h3>
          <h3 className="m2">One Stop Shop</h3>
          <h5 className="section-text">
            <p>
              One stop shop designed for end to end GST Return filing through
              DSC and EVC.
            </p>
          </h5>
        </div>
        <div className="col-4 text-center">
          <h3>
            <FontAwesomeIcon size="lg" icon={faUsersCog} />
          </h3>
          <h3 className="m2">Unlimited Systems & Users</h3>
          <h5 className="section-text">
            <p>
              Works on multiple systems with unlimited users. Anywhere anytime
              access – no installation required.
            </p>
          </h5>
        </div>

        <div className="col-4 text-center">
          <h3>
            <FontAwesomeIcon size="lg" icon={faCheckCircle} />
          </h3>
          <h3 className="m2">Statutory Compliant</h3>
          <h5 className="section-text">
            <p>
              Statutory compliant with updated GST return forms, HSN codes, etc.
            </p>
          </h5>
        </div>
      </div>

      <div id="testimonials" className="banner-bg p3 demo">
        <h1 className="text-center">Testimonials</h1>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <TestimonialSlide testimonial={testimonial} />
          ))}
        </Slider>
      </div>
      <div id="help" className="help box-shadow">
        <div className="text-center">
          <h2>Help</h2>
        </div>
        <div className="help-section">
          <div className="help-video">
            <iframe
              title="video-1"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/-mprjZ6MTCQ"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="help-video">
            <iframe
              title="video-2"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/D-Q3OyTF7fI"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="help-video">
            <iframe
              title="video-3"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/2M_U4oEBJm0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="text-center">
        <a
          title="video-4"
          href="https://www.youtube.com/channel/UCqIIVO7uodfa4yqunkAT86Q"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>
            <FontAwesomeIcon size="lg" icon={faYoutube} />{" "}
            &nbsp;&nbsp;&nbsp;More Videos
          </h2>{" "}
        </a>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    pattern: file(relativePath: { eq: "pattern.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero: file(relativePath: { eq: "hero-opto.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    opto: file(relativePath: { eq: "banner-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tax: file(relativePath: { eq: "tax-professionals.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Index location={props.location} props data={data} {...props} />
    )}
  />
)
