import React from "react"

export const TestimonialSlide = ({ testimonial }) => {
  return (
    <div style={{ width: "100vw" }}>
      <div className="testimonial-slide">
        {testimonial.testimonial.map(t => (
          <p style={{ fontSize: 22, lineHeight: 1.5 }}>{t}</p>
        ))}
        <h3 className="text-right">- {testimonial.author}</h3>
        <h5 className="text-right">{testimonial.about}</h5>
      </div>
    </div>
  )
}
